<template>
  <nav
    v-if="availableLocales.length > 1"
    class="w-full h-16 bg-surface flex items-center justify-end z-40 fixed top-0 px-4"
  >
    <div class="md:pr-6">
      <LocaleSwitcher />
    </div>
  </nav>
  <div
    class="flex min-h-screen flex-col px-4"
    :class="{ 'pt-12': availableLocales.length > 1 }"
  >
    <div class="flex items-center justify-center flex-1 mt-8">
      <div class="bg-surface max-w-[36.25rem] w-full px-6 sm:px-12 rounded-xl">
        <slot />
      </div>
    </div>
    <Footer class="mt-8" />
  </div>
</template>

<script setup lang="ts">
import { LocaleSwitcher } from './_components/top-navigation/_components/locale-switcher';
import { Footer } from '~/components/footer';
import { useOrganizationStore } from '~/store/organization';

const { organization } = storeToRefs(useOrganizationStore());

const availableLocales = computed(() => {
  if (!organization.value) {
    return [];
  }

  return organization.value.locale.available;
});
</script>
